import { NetworkUrlOfChainId, EthRPCEndpoints, ChainType, SubGovTokenAddress, ThetaRPCEndpoints } from 'common/constants';
const host = 'https://testnet-tsub360777-explorer.thetatoken.org';
const hostApi = 'https://testnet-tsub360777-explorer-api.thetatoken.org';
const hostWs = 'https://testnet-tsub360777-explorer-ws.thetatoken.org';
const config = {
    restApi: {
        host: hostApi,
        port: 443
    },
    socketApi: {
        host: hostWs,
        port: 443
    },
    defaultThetaChainID: NetworkUrlOfChainId[host],
    ethRPCEndpoint: EthRPCEndpoints[host],
    thetaRPCEndPoint: ThetaRPCEndpoints[host],
    chainInfo: {
        metachain: {
            host: "https://testnet-metachain-explorer.thetatoken.org"
        },
        mainchain: {
            name: 'Theta Testnet Main Chain',
            description: 'The Main Chain serves as the Metachain Hub and coordinates the Subchains.',
            host: 'https://testnet-explorer.thetatoken.org',
            hostApi: 'https://testnet-explorer.thetatoken.org',
            hostWs: 'https://testnet-explorer.thetatoken.org',
            logoName: "theta",
            restApiPort: 8443,
            socketApiPort: 2096
        },
        subchains: [{
            name: 'Subchain Demo',
            description: 'Subchain Demo, a sandbox environment for developers and users to experience the Theta Metachain technology.',
            host: 'https://testnet-tsub360777-explorer.thetatoken.org',
            hostApi: 'https://testnet-tsub360777-explorer-api.thetatoken.org',
            hostWs: 'https://testnet-tsub360777-explorer-ws.thetatoken.org',
            logoName: "theta",
            restApiPort: 443,
            socketApiPort: 443
        },{
            name: 'Replay Subchain Test',
            description: 'Replay Subchain demo, a subchain dedicated to the Replay, a video tracking and payments platform powered by Theta.',
            host: 'https://testnet-tsub88888-explorer.thetatoken.org',
            hostApi: 'https://testnet-tsub88888-explorer-api.thetatoken.org',
            hostWs: 'https://testnet-tsub88888-explorer-ws.thetatoken.org',
            logoName: "replay",
            restApiPort: 443,
            socketApiPort: 443
        }]
    },
    chainName: 'Subchain Demo',
    chainType: ChainType.SUBCHAIN,
    subGovTokenAddress: SubGovTokenAddress[host],
    tokenMap: {}
};
export default config;
